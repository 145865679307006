.become{
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
}

.left-b > hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0px;
}

.left-b{
    flex: 1;
    position: relative;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
    font-size: 3rem;
}

.right-b{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}



.email-container{
    display: flex;
    gap: 5rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}

::placeholder{
    color: var(--lightgray);
}

.btn-b{
    background-color: var(--orange);
    color: white;

}

@media screen and (max-width : 768px) {
    .become{
        flex-direction: column;
        gap: 1rem;
    }

    .left-b{
        font-size: xx-large;
        flex-direction: column
    }

    .right-b{
        padding: 1rem;
    }
}