.hero{
    display: flex;
    justify-content: space-between;
}

.blur-r {
    height: 30rem;
    left: 0px;
    width: 22rem;
}

.left-h{
    padding: 2rem;
    padding-top: 2rem;
    flex: 3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}

.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);

}

.best{
    margin-top: 2rem;
    background-color: #363d42;
    border-radius: 4rem;  
    width: fit-content;
    padding: 20px 13px;
    color: white;  
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 1px;
}


.best>span{
    z-index: 2;
}

.best>div{
    position: absolute;
    background-color: var(--orange);
    width: 8rem;
    height: 80%;
    left: 8px;
    border-radius: 3rem;
    z-index: 2;
}

.bestext{
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
   color: white;
   text-transform: uppercase;
   font-weight: bold;
   text-overflow: inherit;
   font-size: 5rem;
}

.stroke-text{
    -webkit-text-stroke-width: 1px;
    letter-spacing: 0.1rem;
    -webkit-text-stroke-color: white;
    color: transparent;
}

.bestext>div:nth-of-type(3){
    font-style: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1.1px;
    width: 70%;
    font-size: 0.8rem;

}

.figures{
    display: flex;
    gap: 2rem;
}

.figures>div{
    display: flex;
    flex-direction: column;
}

.figures>div>span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){
    color: var(--gray);
    text-transform: none;
}

.bttn{
    display: flex;
    gap : 1rem;
    font-weight: normal;
}

.bttn>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 8rem;
}

.bttn>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
}

.right-h>.btn{
    position: absolute;
    right: 3rem; top: 2rem; color: black;
}

.image{
    position: absolute;
    right: -4rem;
    top: 20rem;
    width: 50rem;
}

.image_back{
    position: absolute;
    top: 14rem;
    right: 20rem;
    z-index: -1;
}

@media screen and (max-width: 768px) {


    .hero{
        flex-direction: column;
    }

    .blur-r{
        width: 14rem;
    }
    
    .best {
        align-self: center;
        font-size: small;
        margin-top: 0px;
        transform: scale(0.8);
    }

    .bestext{
        font-size: 2.5rem;
        align-items: center;
        justify-content: center;
    }

    .bestext>div:nth-of-type(3){
        font-size: small;
        font-weight: 100;
        letter-spacing: 1.2px;
        text-align: left;
    }

    .figures>div>span:nth-of-type(1){
        font-size: large;   

    }

    .figures>div>span:nth-of-type(2){
        font-size: small;   
        
    }

    .right-h {
        position: relative;
        background: none;
    }

    .image{
        position: relative;
        width: 25rem;
        left: -1.5rem;
        top: 4.8rem;
        align-self: center;   
    }

    .image_back{
        width: 10rem;
        left: 1.9rem;
        top: -6rem;
    }
}

