.Final>:nth-child(1){
    width: 100%;
    top:2rem;
    height: 0.3rem;
    background-color: white;
}

.Final{
    position: relative;
}

.footer, .socials {
    display: flex;
    gap: 4rem;
}

.footer {
    align-items: center;
    flex-direction: column;
    height: 20rem;
    justify-content: center;
    padding: 1rem 2rem;
}

.socials > img {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
}

.logo1{
    justify-content: center;
}

.Final > :nth-child(3), .Final > :nth-child(4) {
    bottom: 0px;
    filter: blur(200px);
    height: 12rem;
    width: 26rem;
}

.Final > :nth-child(3) {
    right: 15%;
    background: red;
}

.Final > :nth-child(4) {
    left: 15%;
    background: rgb(255, 115, 0);
}